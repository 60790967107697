import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

import { deleteEmployee, RootState } from '@shared/store';
import { Truck as TruckInterface, LeaseTruck as LeaseTruckInterface } from '@shared/types';
import { TrucksState } from './types';
import {
  getTrucks,
  createTruck,
  updateTruck,
  createLeaseTruck,
  updateLeaseTruck,
  deleteTruck,
  deleteLeaseTruck
} from './thunks';

const initialState: TrucksState = {
  allTrucks: {
    libsTrucks: [],
    leaseTrucks: []
  },
  isLoaded: false,
  isLoading: false,
  isSubmitting: false,
  activeTruck: null,
  errorMessages: []
};

const trucksSlice = createSlice({
  name: 'trucks',
  initialState,
  reducers: {
    startSubmitTrucks(state) {
      state.isSubmitting = true;
    },
    endSubmitTrucks(state) {
      state.isSubmitting = false;
    },
    startLoadTrucks(state) {
      state.isLoading = true;
    },
    endLoadTrucks(state) {
      state.isLoading = false;
    },
    setTrucksError(state, action: PayloadAction<string[]>) {
      state.errorMessages = action.payload;
    },
    setActiveTruck(state, action: PayloadAction<TruckInterface | LeaseTruckInterface | null>) {
      state.activeTruck = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(getTrucks.pending, state => {
      state.isLoading = true;
    });

    builder.addCase(getTrucks.fulfilled, (state, action) => {
      const [libsTrucks, leaseTrucks] = action.payload;

      state.allTrucks = {
        libsTrucks: libsTrucks.data,
        leaseTrucks: leaseTrucks.data
      };
      state.page = libsTrucks.page;
      state.isLoading = false;
      state.isLoaded = true;
    });

    builder.addCase(createTruck.fulfilled, (state, action) => {
      state.allTrucks.libsTrucks = state.allTrucks.libsTrucks.concat([action.payload]);
      state.activeTruck = action.payload;
      state.isSubmitting = false;
    });

    builder.addCase(createTruck.rejected, (state, action) => {
      if (action.error.message) {
        state.errorMessages = action.error.message.split('|');
      }

      state.isSubmitting = false;
    });

    builder.addCase(createLeaseTruck.fulfilled, (state, action) => {
      state.allTrucks.leaseTrucks = state.allTrucks.leaseTrucks.concat([action.payload]);
      state.activeTruck = action.payload;
      state.isSubmitting = false;
    });

    builder.addCase(createLeaseTruck.rejected, (state, action) => {
      if (action.error.message) {
        state.errorMessages = action.error.message.split('|');
      }

      state.isSubmitting = false;
    });

    builder.addCase(updateTruck.fulfilled, (state, action) => {
      const index = state.allTrucks.libsTrucks.findIndex(truck => truck.id === action.payload.id);

      if (index !== -1) {
        state.allTrucks.libsTrucks[index] = action.payload;
      }

      state.activeTruck = action.payload;
      state.isSubmitting = false;
    });

    builder.addCase(updateTruck.rejected, (state, action) => {
      if (action.error.message) {
        state.errorMessages = action.error.message.split('|');
      }

      state.isSubmitting = false;
    });

    builder.addCase(updateLeaseTruck.fulfilled, (state, action) => {
      const index = state.allTrucks.leaseTrucks.findIndex(
        leaseTruck => leaseTruck.id === action.payload.id
      );

      if (index !== -1) {
        state.allTrucks.leaseTrucks[index] = action.payload;
      }

      state.activeTruck = action.payload;
      state.isSubmitting = false;
    });

    builder.addCase(updateLeaseTruck.rejected, (state, action) => {
      if (action.error.message) {
        state.errorMessages = action.error.message.split('|');
      }

      state.isSubmitting = false;
    });

    builder.addCase(deleteTruck.pending, state => {
      state.isSubmitting = true;
    });

    builder.addCase(deleteTruck.fulfilled, (state, { payload: didDelete }) => {
      const index = state.allTrucks.libsTrucks.findIndex(
        employee => employee.id === state.activeTruck?.id
      );

      if (index >= 0) {
        state.allTrucks.libsTrucks[index].isDiscarded = didDelete;
      }

      state.activeTruck = null;
      state.isSubmitting = false;
    });

    builder.addCase(deleteTruck.rejected, (state, action) => {
      if (action.error.message) {
        state.errorMessages = action.error.message.split('|');
      }

      state.isSubmitting = false;
    });

    builder.addCase(deleteLeaseTruck.pending, state => {
      state.isSubmitting = true;
    });

    builder.addCase(deleteLeaseTruck.fulfilled, (state, { payload: didDelete }) => {
      const index = state.allTrucks.leaseTrucks.findIndex(
        employee => employee.id === state.activeTruck?.id
      );

      if (index >= 0) {
        state.allTrucks.leaseTrucks[index].isDiscarded = didDelete;
      }

      state.activeTruck = null;
      state.isSubmitting = false;
    });

    builder.addCase(deleteLeaseTruck.rejected, (state, action) => {
      if (action.error.message) {
        state.errorMessages = action.error.message.split('|');
      }

      state.isSubmitting = false;
    });
  }
});

export const {
  startSubmitTrucks,
  endSubmitTrucks,
  startLoadTrucks,
  endLoadTrucks,
  setTrucksError,
  setActiveTruck
} = trucksSlice.actions;
export default trucksSlice.reducer;

export const useTrucksSelector = (): TrucksState =>
  useSelector<RootState, TrucksState>(state => state.trucks);
