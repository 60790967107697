import React from 'react';
import { Alert } from 'react-bootstrap';

interface FormErrorsProps {
  errors: string[];
}

export const FormErrors: React.FC<FormErrorsProps> = ({ errors = [], className }) => {
  if (errors.length === 0) return null;

  return (
    <Alert variant="danger">
      <Alert.Heading>Please correct the following errors:</Alert.Heading>
      {errors.map((error, index) => (
        <p className="mb-0" key={`FormError_${index}`}>
          {error}
        </p>
      ))}
    </Alert>
  );
};
