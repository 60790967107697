import React from 'react';
import classnames from 'classnames';

import { Icon, IconProps, IconSize } from '@components/common/icon';
import icons from 'assets/config/icons.json';

export interface IconWithTextProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
  /** The name of the icon to display. */
  iconName: string;
  /** The size of the icon. */
  iconSize?: IconSize;
  /** Content to display. Will typically be text-based, but is not limited to text. */
  children: React.ReactNode;
  /** Aligns all content to the left, center or right. */
  alignment?: 'left' | 'right' | 'center';
  /** Sets the position of the icon in relation to the content. */
  iconPosition?: 'left' | 'right';
  /** Sets the vertical alignment of the icon in relation to the text. */
  verticalAlignment?: 'top' | 'middle' | 'bottom';
  /** Reduces the space between icon and text by half. */
  isTight?: boolean;
  /** Props to pass directly to the nested `Icon` instance. */
  iconProps?: Partial<IconProps>;
}

export const IconWithText: React.FC<IconWithTextProps> = ({
  iconName,
  iconSize,
  children,
  verticalAlignment = 'middle',
  alignment = 'left',
  iconPosition = 'left',
  isTight = false,
  className,
  iconProps = {},
  ...otherProps
}) => (
  <span
    className={classnames(
      'd-flex',
      {
        'align-items-start': verticalAlignment === 'top',
        'align-items-middle': verticalAlignment === 'middle',
        'align-items-end': verticalAlignment === 'bottom',
        'justify-content-start': alignment === 'left' && iconPosition !== 'left',
        'justify-content-end':
          (alignment === 'right' && iconPosition !== 'right') ||
          (alignment === 'left' && iconPosition === 'right'),
        'justify-content-center': alignment === 'center',
        'flex-row-reverse': iconPosition === 'right'
      },
      className
    )}
    {...otherProps}
  >
    <span className="d-inline-flex align-items-center">
      <Icon name={iconName} size={iconSize} {...iconProps} />
    </span>
    <span
      className={classnames({
        'ml-2': iconPosition === 'left' && !isTight,
        'mr-2': iconPosition === 'right' && !isTight,
        'ml-1': iconPosition === 'left' && isTight,
        'mr-1': iconPosition === 'right' && isTight
      })}
    >
      {children}
    </span>
  </span>
);
