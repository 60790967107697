import React, { useState, useEffect, useCallback } from 'react';
import { isEqual, omit } from 'lodash-es';
import { Button } from 'react-bootstrap';

import {
  IconWithText,
  FilterControlInterface,
  FilterValueInterface,
  FilterControl
} from '@components/common';
import '../styles/resource-grid-filters.scss';

export interface ResourceGridFiltersProps {
  activeFilterValues: FilterValueInterface;
  setActiveFilterValues: React.UseStateAction<FilterValueInterface>;
  filterControls: FilterControlInterface[];
  requireManualUpdate?: boolean;
}

export const ResourceGridFilters = ({
  filterControls,
  activeFilterValues,
  setActiveFilterValues,
  requireManualUpdate = true
}: ResourceGridFiltersProps): JSX.Element => {
  const [filterValues, setFilterValues] = useState(activeFilterValues);

  const handleApplyFilters = useCallback(() => {
    setActiveFilterValues(filterValues);
  }, [setActiveFilterValues, filterValues]);

  const handleFilterControlChange = useCallback(
    (value: string, slug: string) => {
      const updateFilterValues = (oldObj: FilterValueInterface): FilterValueInterface => {
        return value ? { ...oldObj, [slug]: value } : omit(oldObj, [slug]);
      };

      setFilterValues(updateFilterValues(filterValues));

      if (!requireManualUpdate) {
        setActiveFilterValues(updateFilterValues(activeFilterValues));
      }
    },
    [filterValues, requireManualUpdate, setActiveFilterValues, activeFilterValues]
  );

  const handleClearFilterControl = useCallback(() => {
    setFilterValues({});
    setActiveFilterValues({});
  }, [setActiveFilterValues, setFilterValues, requireManualUpdate]);

  const handleFilterControlKeyUp = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') handleApplyFilters();
    },
    [handleApplyFilters]
  );

  useEffect(() => {
    setFilterValues(activeFilterValues);
  }, [activeFilterValues, setFilterValues]);

  return (
    <div className="resource-grid-filters">
      <div className="mr-5">
        <IconWithText iconName="settings" className="h4 mb-0 font-weight-bold">
          Filters
        </IconWithText>
        <button
          className="border-0 bg-transparent text-primary p-0 mt-2 d-block"
          onClick={handleClearFilterControl}
        >
          Clear Filters
        </button>
      </div>
      <div className="resource-grid-filters__panel d-flex">
        {filterControls.map((control, index) => (
          <FilterControl
            key={`FilterControl_${index}`}
            {...control}
            requireManualUpdate={requireManualUpdate}
            value={filterValues[control.slug]}
            onChange={handleFilterControlChange}
            onKeyUp={handleFilterControlKeyUp}
          />
        ))}
      </div>
      {requireManualUpdate && (
        <div className="resource-grid-filters__apply ml-3">
          <Button
            variant="secondary"
            onClick={handleApplyFilters}
            disabled={isEqual(filterValues, activeFilterValues)}
          >
            Update
          </Button>
        </div>
      )}
    </div>
  );
};
