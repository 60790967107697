import React, { useMemo } from 'react';
import { Form } from 'react-bootstrap';
import classnames from 'classnames';
import { Field } from 'react-final-form';

import { SelectOption } from '@shared/types';
import './styles/radio-buttons-field.scss';

export interface RadioButtonsFieldProps {
  name: string;
  options: SelectOption<string | boolean>[];
  label?: string;
  isInline?: boolean;
  isDisabled?: boolean;
}

export const RadioButtonsField: React.FC<RadioButtonsFieldProps> = ({
  name,
  label: fieldLabel,
  options,
  isInline = false,
  isDisabled = false
}) => {
  return (
    <Form.Group controlId={name}>
      {fieldLabel && <Form.Label>{fieldLabel}</Form.Label>}
      <div
        className={classnames('radio-buttons-field', {
          'd-flex': isInline,
          'align-items-center': isInline
        })}
      >
        {options.map(({ label, value }, index) => {
          const id = `${name}_${index}_radio_buttons_field`;

          return (
            <React.Fragment key={`radioButton_${name}_${index}`}>
              <div
                className={classnames('d-flex align-items-baseline', {
                  'mr-3': isInline
                })}
              >
                <Field
                  name={name}
                  component="input"
                  type="radio"
                  id={id}
                  value={value}
                  disabled={isDisabled}
                  className="mr-2"
                />
                <label htmlFor={id}>{label}</label>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </Form.Group>
  );
};
