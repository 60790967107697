import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@shared/store';
import { DetailedJob, ListedJob } from '@shared/types';
import { byId } from '@shared/utils';
import { useSelector } from 'react-redux';
import { JobsState } from './types';
import {
  createServiceDetail,
  createJobBillingRequests,
  getJobDetails,
  getJobFilters,
  getJobs,
  updateJobDetails,
  updateJobReport,
  updateListedJob,
  updateServiceDetail,
  updateJobBillingRequests
} from './thunks';
import { omit } from 'lodash-es';
import { useMemo } from 'react';

const initialState: JobsState = {
  jobs: [],
  totalCount: 0,
  isLoading: false,
  filters: [],
  materialTypes: [],
  isLoadingFilters: false,
  errorMessages: [],
  jobDetails: undefined,
  isLoadingJobDetails: false,
  isSubmittingServiceDetail: false,
  isSubmittingJobDetails: false
};

const jobsSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {
    setJobsError(state, action: PayloadAction<string[]>) {
      state.errorMessages = action.payload;
    },
    updateAllJobs(state, action: PayloadAction<ListedJob[]>) {
      state.jobs = action.payload;
    },
    setJobDetails(state, action: PayloadAction<DetailedJob>) {
      state.jobDetails = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(getJobs.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getJobs.fulfilled, (state, action) => {
      const { data, totalCount } = action.payload;
      state.jobs = data;
      state.totalCount = totalCount;
      state.isLoading = false;
    });
    builder.addCase(getJobs.rejected, (state, action) => {
      state.errorMessages = action.error.message?.split('|') ?? [];
    });

    builder.addCase(getJobFilters.pending, (state, action) => {
      state.isLoadingFilters = true;
    });
    builder.addCase(getJobFilters.fulfilled, (state, action) => {
      state.filters = action.payload;
      state.isLoadingFilters = true;
    });
    builder.addCase(getJobFilters.rejected, (state, action) => {
      state.errorMessages = action.error.message?.split('|') ?? [];
    });

    builder.addCase(getJobDetails.pending, (state, action) => {
      state.isLoadingJobDetails = true;
    });
    builder.addCase(getJobDetails.fulfilled, (state, action) => {
      state.jobDetails = action.payload;
      state.isLoadingJobDetails = false;
    });
    builder.addCase(getJobDetails.rejected, (state, action) => {
      state.errorMessages = action.error.message?.split('|') ?? [];
    });

    builder.addCase(updateJobDetails.pending, (state, action) => {
      state.isSubmittingJobDetails = true;
    });
    builder.addCase(updateJobDetails.fulfilled, (state, action) => {
      state.jobDetails = action.payload;
      state.isSubmittingJobDetails = false;
    });
    builder.addCase(updateJobDetails.rejected, (state, action) => {
      if (action.error.message) {
        state.errorMessages = action.error.message.split('|');
      }
      state.isSubmittingJobDetails = false;
    });

    builder.addCase(updateListedJob.pending, (state, action) => {
      const job = state.jobs.find(byId(action.meta.arg.id));
      if (job) {
        job.isSubmitting = true;
      }
    });
    builder.addCase(updateListedJob.fulfilled, (state, action) => {
      const jobIndex = state.jobs.findIndex(byId(action.payload.id));
      if (jobIndex !== -1) {
        state.jobs[jobIndex] = action.payload;
      }
    });
    builder.addCase(updateListedJob.rejected, (state, action) => {
      const job = state.jobs.find(byId(action.meta.arg.id));
      if (job) {
        job.errorMessages = action.error.message?.split('|');
        job.isSubmitting = false;
      }
    });

    builder.addCase(updateJobReport.pending, state => {
      state.isSubmittingJobDetails = true;
    });
    builder.addCase(updateJobReport.fulfilled, state => {
      state.isSubmittingJobDetails = false;
    });

    builder.addCase(createServiceDetail.pending, state => {
      state.isSubmittingServiceDetail = true;
    });
    builder.addCase(createServiceDetail.fulfilled, state => {
      state.isSubmittingServiceDetail = false;
    });

    builder.addCase(updateServiceDetail.pending, state => {
      state.isSubmittingServiceDetail = true;
    });
    builder.addCase(updateServiceDetail.fulfilled, state => {
      state.isSubmittingServiceDetail = false;
    });

    builder.addCase(updateJobBillingRequests.pending, state => {
      state.isSubmittingJobDetails = true;
    });

    builder.addCase(updateJobBillingRequests.fulfilled, (state, action) => {
      const jobIndex = state.jobs.findIndex(byId(action.payload[0]?.jobId ?? 0));
      const total = action.payload.reduce((acc, curr) => acc + Number(curr.total), 0);

      if (jobIndex !== -1) {
        const job = state.jobs[jobIndex];
        state.jobs[jobIndex] = {
          ...job,
          readyToBill: true,
          readyToBillChangedAt: action.payload[0]?.updatedAt ?? new Date().toISOString(),
          billingRequests: action.payload,
          billedAmount: total
        } as DetailedJob;
      }

      state.isSubmittingJobDetails = false;
    });

    builder.addCase(createJobBillingRequests.pending, state => {
      state.isSubmittingJobDetails = true;
    });

    builder.addCase(createJobBillingRequests.fulfilled, (state, action) => {
      const jobIndex = state.jobs.findIndex(byId(action.payload[0]?.jobId ?? 0));
      const total = action.payload.reduce((acc, curr) => acc + Number(curr.total), 0);
      const statusName = action.payload[0]?.jobStatusName;

      if (jobIndex !== -1) {
        const job = state.jobs[jobIndex] as DetailedJob;
        state.jobs[jobIndex] = {
          ...job,
          statusName,
          readyToBill: true,
          billingRequests: [
            ...job.billingRequests.map(billingRequest => omit(billingRequest, ['jobStatusName'])),
            ...action.payload
          ],
          readyToBillChangedAt: action.payload[0]?.updatedAt ?? new Date().toISOString(),
          billedAmount: total
        } as DetailedJob;
      }

      state.isSubmittingJobDetails = false;
    });
  }
});

export const { setJobsError, updateAllJobs, setJobDetails } = jobsSlice.actions;
export default jobsSlice.reducer;

export const useJobsSelector = (): JobsState =>
  useSelector<RootState, JobsState>(state => state.jobs);

export const useJobDetailsSelector = (
  jobId: number
): Pick<JobsState, 'jobDetails' | 'isLoadingJobDetails'> => {
  const { jobDetails, isLoadingJobDetails } = useSelector<RootState, JobsState>(
    state => state.jobs
  );
  return useMemo(
    () => ({
      jobDetails: jobDetails?.id === jobId ? jobDetails : undefined,
      isLoadingJobDetails
    }),
    [isLoadingJobDetails, jobDetails, jobId]
  );
};
