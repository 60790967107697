import { has, isObject } from 'lodash-es';
import { RailsObject } from './RailsObject';
import { Employee } from './Employee';
import { Division } from './Division';

export interface Truck extends RailsObject {
  division?: Division | undefined;
  division_id?: number | null;
  defaultDriver?: Employee;
  defaultDriverId?: number;
  displayedName: string;
  hourlyRate: number;
  makeAndModel: string;
  isDiscarded: boolean;
  photo?: string | null;
  photoPath?: string;
  truckType: 'libs_truck' | 'libs_triaxles';
  unitId: string;
  vin: string;
  year: number;
  type?: string;
}

export const isTruck = (obj: unknown): obj is Truck => {
  return isObject(obj) && has(obj, 'truckType');
};

export const isTriaxle = (obj: unknown): obj is Truck => {
  return isTruck(obj) && obj.truckType === 'libs_triaxles';
};

const isThatType = (truck: Truck, truckType: Truck['truckType']): boolean => {
  return isTruck(truck) && truck.truckType === truckType;
}

export const isLibsTruck = (truck: Truck) => isThatType(truck, 'libs_truck');
export const isTriaxleTruck = (truck: Truck) => isThatType(truck, 'libs_triaxles');
