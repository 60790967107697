import { Can } from '@shared/authorization';
import React, { useCallback, useMemo } from 'react';
import { Nav } from 'react-bootstrap';
import { useLocation } from 'react-router';
import { LinkContainer } from 'react-router-bootstrap';

interface ScheduleLayoutProps {
  enabled: boolean;
  setEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
}

interface ChildComponentProps {
  setNavEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
}

const assertChildComponent = (
  child: React.ReactNode
): child is React.ReactElement<ChildComponentProps> =>
  React.isValidElement<ChildComponentProps>(child);

export const ScheduleLayout: React.FC<ScheduleLayoutProps> = ({
  enabled,
  setEnabled,
  setEditMode,
  children
}) => {
  const location = useLocation();
  const pathDivision = useMemo(() => location.pathname.split('/').pop(), [location]);

  const getTextStyles = (link: string) => {
    return {
      color: enabled || link === (pathDivision ?? '') ? 'inherit' : '#ccc'
    };
  };

  return (
    <div className="admin-layout">
      <Nav variant="tabs" className="mt-3">
        <Can I="read" a="AllSchedule">
          <LinkContainer to="/schedule-v2/all">
            <Nav.Link disabled={!enabled}>
              <span style={getTextStyles('all')}>All</span>
            </Nav.Link>
          </LinkContainer>
        </Can>
        <Can I="read" a="PavingSchedule">
          <LinkContainer to="/schedule-v2/paving">
            <Nav.Link disabled={!enabled}>
              <span style={getTextStyles('paving')}>Paving</span>
            </Nav.Link>
          </LinkContainer>
        </Can>
        <Can I="read" a="ConcreteSchedule">
          <LinkContainer to="/schedule-v2/concrete">
            <Nav.Link disabled={!enabled}>
              <span style={getTextStyles('concrete')}>Concrete</span>
            </Nav.Link>
          </LinkContainer>
        </Can>
        <Can I="read" a="StripingSchedule">
          <LinkContainer to="/schedule-v2/striping">
            <Nav.Link disabled={!enabled}>
              <span style={getTextStyles('striping')}>Striping</span>
            </Nav.Link>
          </LinkContainer>
        </Can>
        <Can I="read" a="SealingSchedule">
          <LinkContainer to="/schedule-v2/sealing">
            <Nav.Link disabled={!enabled}>
              <span style={getTextStyles('sealing')}>Sealing</span>
            </Nav.Link>
          </LinkContainer>
        </Can>
      </Nav>
      {React.Children.map(children, child => {
        if (assertChildComponent(child)) {
          return React.cloneElement(child, { setNavEnabled: setEnabled, setEditMode: setEditMode });
        }
        return child;
      })}
    </div>
  );
};
