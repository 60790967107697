import React, { Suspense, useContext, useEffect, useMemo, useState } from 'react';
import {
  Switch,
  Route,
  Link,
  useHistory,
  useLocation,
  Redirect,
  RouteProps
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Container,
  Row,
  Navbar,
  Nav,
  NavDropdown,
  Button,
  OverlayTrigger,
  Tooltip,
  Col,
  Spinner
} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import {
  CurrentUserState,
  RootState,
  getCurrentUser,
  logout,
  updateAllJobs,
  updateScheduleJobs,
  useAppDispatch,
  setActiveSchedule,
  setCrewListTemplates,
  AppDispatch
} from '@shared/store';
import { SessionService } from '@shared/modules';
import {
  ErrorBoundary,
  GlobalAlerts,
  GoToJobModal,
  Icon,
  IconWithText,
  Loading
} from '@components/common';
import { AdminLayout } from '@components/layouts';
import pavedayLogo from 'assets/images/paveday-logo.webp';
import pavedayLogo2x from 'assets/images/paveday-logo@2x.webp';
import pavedayLogo3x from 'assets/images/paveday-logo@3x.webp';
import { routes, userRolesEnum } from '@shared/constants';
import { AbilityContext, Can } from '@shared/authorization';
import { ScheduleLayout } from './layouts/ScheduleLayout';

// prettier-ignore
const SignIn = React.lazy(() => import(/* webpackChunkName: "libs-sign-in" */ "./pages/SignIn"));
// prettier-ignore
const Schedule = React.lazy(() => import(/* webpackChunkName: "libs-schedule" */ "./pages/schedule/Schedule"));
// prettier-ignore
const ScheduleV2 = React.lazy(() => import(/* webpackChunkName: "libs-schedule" */ "./pages/schedule-v2/ScheduleV2"));
// prettier-ignore
const Jobs = React.lazy(() => import(/* webpackChunkName: "libs-jobs" */ "./pages/jobs/Jobs"));
// prettier-ignore
const Job = React.lazy(() => import(/* webpackChunkName: "libs-job" */ "./pages/jobs/job/Job"));
// prettier-ignore
const JobReview = React.lazy(() => import(/* webpackChunkName: "libs-job-review" */ "./pages/reporting/job-review/JobReview"));
// prettier-ignore
const Users = React.lazy(() => import(/* webpackChunkName: "libs-admin-users" */ "./pages/admin/Users"));
// prettier-ignore
const ResourceManagement = React.lazy(() => import(/* webpackChunkName: "libs-admin-resource-management" */ "./pages/admin/ResourceManagement"));
// prettier-ignore
const ReadyToBill = React.lazy(() => import(/* webpackChunkName: "libs-reporting-ready-to-bill" */ "./pages/reporting/ready-to-bill/ReadyToBill"));
// prettier-ignore
const HoursByState = React.lazy(() => import(/* webpackChunkName: "libs-reporting-hours-by-state" */ "./pages/reporting/HoursByState"));
// prettier-ignore
const ConesLeftBehind = React.lazy(() => import(/* webpackChunkName: "libs-reporting-hours-by-state" */ "./pages/reporting/ConesLeftBehind"));
// prettier-ignore
const DailyTruckReport = React.lazy(() => import(/* webpackChunkName: "libs-reporting-hours-by-state" */ "./pages/reporting/daily-truck-report/DailyTruckReport"));
// prettier-ignore
const ProfitMarginReport = React.lazy(() => import(/* webpackChunkName: "libs-profit-margin-report" */ "./pages/reporting/ProfitMarginReport"));
// prettier-ignore
const SchedulePublishedReport = React.lazy(() => import(/* webpackChunkName: "libs-schedule-published-report" */ "./pages/reporting/SchedulePublishedReport"));
// prettier-ignore
const ScheduledJobsReport = React.lazy(() => import(/* webpackChunkName: "libs-scheduled-jobs-report" */ "./pages/reporting/ScheduledJobsReport"));

const {
  FOREMAN,
  SALES_REP,
  ADMIN,
  PAVING_SCHEDULER,
  CONCRETE_SCHEDULER,
  STRIPING_SCHEDULER,
  SEALING_SCHEDULER,
  LEASE_TRUCK_OPERATOR,
  SCHEDULE_VIEWER,
  SUPERINTENDENT
} = userRolesEnum;

interface AuthorizedRouteProps extends RouteProps {
  isAuthorized: boolean;
}

interface ScheduleRouteProps extends AuthorizedRouteProps {
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
}

const AuthorizedRoute = ({
  isAuthorized,
  component: Component,
  render,
  ...rest
}: AuthorizedRouteProps) => {
  return (
    <Route
      {...rest}
      render={props => (
        // if we don't specify a key, the ErrorBoundary component is preserved between
        // route changes and error in one route will cause components for other routes
        // not to be rendered
        <ErrorBoundary key={props.location.pathname}>
          {isAuthorized ? (
            Component ? (
              <Component {...props} />
            ) : (
              render?.(props)
            )
          ) : (
            <Redirect to="/" />
          )}
        </ErrorBoundary>
      )}
    />
  );
};

const AdminRoute = ({ component: Component, ...rest }: AuthorizedRouteProps) => {
  return (
    <AuthorizedRoute
      {...rest}
      render={matchProps => (
        <AdminLayout {...matchProps}>{Component && <Component {...matchProps} />}</AdminLayout>
      )}
    />
  );
};

const ScheduleRoute = ({ component: Component, ...rest }: ScheduleRouteProps) => {
  const [navEnabled, setNavEnabled] = useState<boolean>(true);

  return (
    <AuthorizedRoute
      {...rest}
      render={matchProps => (
        <ScheduleLayout
          enabled={navEnabled ?? true}
          setEnabled={setNavEnabled}
          setEditMode={rest.setEditMode}
          {...matchProps}
        >
          {Component && <Component {...matchProps} />}
        </ScheduleLayout>
      )}
    />
  );
};

const logoutAndClearData = () => async (dispatch: AppDispatch) => {
  dispatch(logout());
  dispatch(setActiveSchedule(null));
  dispatch(setCrewListTemplates([]));
  dispatch(updateAllJobs([]));
  dispatch(updateScheduleJobs([]));
};

const App = (): JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { isLoggedIn, currentUser } = useSelector<RootState, CurrentUserState>(
    state => state.currentUser
  );
  const ability = useContext(AbilityContext);

  const [editMode, setEditMode] = useState<boolean>(false);

  const currentUserHome = useMemo(() => {
    switch (currentUser?.role) {
      case SALES_REP:
        return '/jobs';
      case ADMIN:
        return '/schedule-v2/all';
      case PAVING_SCHEDULER:
        return '/schedule-v2/paving';
      case CONCRETE_SCHEDULER:
        return '/schedule-v2/concrete';
      case STRIPING_SCHEDULER:
        return '/schedule-v2/striping';
      case SEALING_SCHEDULER:
        return '/schedule-v2/sealing';
      case SCHEDULE_VIEWER:
        return `/schedule-v2/all`;
      case SUPERINTENDENT:
        return '/schedule-v2/paving';
    }
    return '/';
  }, [currentUser]);

  const showGoToJobModal = useMemo(() => {
    return !['/sign-in'].includes(location.pathname) && !editMode && ability.can('read', 'Job');
  }, [location, ability, editMode]);

  const canSeeReporting = useMemo(() => {
    return (
      ability.can('read', 'ReadyToBillReport') ||
      ability.can('read', 'HoursByStateReport') ||
      ability.can('read', 'ConesLeftBehindReport') ||
      ability.can('read', 'DailyTruckReport') ||
      ability.can('read', 'ProfitMarginReport') ||
      ability.can('read', 'SchedulePublishedReport') ||
      ability.can('read', 'ScheduledJobsReport') ||
      ability.can('read', 'Job')
    );
  }, [ability]);

  useEffect(() => {
    if (!isLoggedIn) {
      if (SessionService.hasSession()) {
        dispatch(getCurrentUser());
      } else {
        history.replace(routes.SIGN_IN);
      }
    } else {
      if (!currentUser) {
        dispatch(getCurrentUser());
      }
    }
  }, [isLoggedIn, currentUser, dispatch, history]);

  useEffect(() => {
    if (isLoggedIn && location.pathname === routes.SIGN_IN) {
      const loginRedirect = SessionService.loginRedirect;
      SessionService.loginRedirect = null;
      history.replace(loginRedirect ?? currentUserHome);
    }
  }, [location, isLoggedIn, history, currentUserHome]);

  const handleLogout = () => {
    dispatch(logoutAndClearData());
    window.location.reload();
  };

  const testErrorHandling = () => {
    throw new Error('Test Error Handling');
  };

  return (
    <ErrorBoundary>
      {isLoggedIn && (
        <Navbar bg="light">
          <Container fluid="xl">
            <Navbar.Brand href="/">
              <img
                srcSet={`${pavedayLogo} 1x, ${pavedayLogo2x} 2x, ${pavedayLogo3x} 3x`}
                height="52"
                width="52"
                alt="Libs Paving Scheduler"
              />
            </Navbar.Brand>
            <Nav className="mr-auto">
              <Can I="read" a="Schedule">
                <NavDropdown title="Schedule" id="scheduleMenu">
                  <Can I="read" a="AllSchedule">
                    <LinkContainer to="/schedule-v2/all">
                      <NavDropdown.Item href="/schedule-v2/all">All</NavDropdown.Item>
                    </LinkContainer>
                  </Can>
                  <Can I="read" a="PavingSchedule">
                    <LinkContainer to="/schedule-v2/paving">
                      <NavDropdown.Item href="/schedule-v2/paving">Paving</NavDropdown.Item>
                    </LinkContainer>
                  </Can>
                  <Can I="read" a="ConcreteSchedule">
                    <LinkContainer to="/schedule-v2/concrete">
                      <NavDropdown.Item href="/schedule-v2/concrete">Concrete</NavDropdown.Item>
                    </LinkContainer>
                  </Can>
                  <Can I="read" a="StripingSchedule">
                    <LinkContainer to="/schedule-v2/striping">
                      <NavDropdown.Item href="/schedule-v2/striping">Striping</NavDropdown.Item>
                    </LinkContainer>
                  </Can>
                  <Can I="read" a="SealingSchedule">
                    <LinkContainer to="/schedule-v2/sealing">
                      <NavDropdown.Item href="/schedule-v2/sealing">Sealing</NavDropdown.Item>
                    </LinkContainer>
                  </Can>
                </NavDropdown>
              </Can>

              <Can I="read" a="Job">
                <Nav.Link as={Link} to="/jobs" title="Jobs">
                  Jobs
                </Nav.Link>
              </Can>

              {(ability.can('read', 'user') || ability.can('read', 'Resource')) && (
                <NavDropdown title="Admin" id="adminMenu">
                  <Can I="read" a="User">
                    <LinkContainer to="/admin/users">
                      <NavDropdown.Item href="/admin/users">Users</NavDropdown.Item>
                    </LinkContainer>
                  </Can>

                  <Can I="read" a="Resource">
                    <LinkContainer to="/admin/resource-management">
                      <NavDropdown.Item href="/admin/resource-management">
                        Resource Management
                      </NavDropdown.Item>
                    </LinkContainer>
                  </Can>
                </NavDropdown>
              )}

              {canSeeReporting && (
                <NavDropdown title="Reporting" id="reportingMenu">
                  <Can I="read" a="Job">
                    <LinkContainer to="/reporting/job-review">
                      <NavDropdown.Item href="/reporting/job-review">Job Review</NavDropdown.Item>
                    </LinkContainer>
                  </Can>
                  <Can I="read" a="ReadyToBillReport">
                    <LinkContainer to="/reporting/ready-to-bill">
                      <NavDropdown.Item href="/reporting/ready-to-bill">
                        Ready To Bill
                      </NavDropdown.Item>
                    </LinkContainer>
                  </Can>
                  <Can I="read" a="ProfitMarginReport">
                    <LinkContainer to="/reporting/profit-margin-report">
                      <NavDropdown.Item href="/reporting/profit-margin-report">
                        Profit Margin
                      </NavDropdown.Item>
                    </LinkContainer>
                  </Can>
                  <Can I="read" a="SchedulePublishedReport">
                    <LinkContainer to="/reporting/schedule-published-report">
                      <NavDropdown.Item href="/reporting/schedule-published-report">
                        Schedule Published
                      </NavDropdown.Item>
                    </LinkContainer>
                  </Can>
                  <Can I="read" a="ScheduledJobsReport">
                    <LinkContainer to="/reporting/scheduled-jobs-report">
                      <NavDropdown.Item href="/reporting/scheduled-jobs-report">
                        Scheduled Jobs
                      </NavDropdown.Item>
                    </LinkContainer>
                  </Can>
                  <Can I="read" a="HoursByStateReport">
                    <LinkContainer to="/reporting/hours-by-state">
                      <NavDropdown.Item href="/reporting/hours-by-state">
                        Hours By State
                      </NavDropdown.Item>
                    </LinkContainer>
                  </Can>
                  <Can I="read" a="ConesLeftBehindReport">
                    <LinkContainer to="/reporting/cones-left-behind">
                      <NavDropdown.Item href="/reporting/cones-left-behind">
                        Cones Left Behind
                      </NavDropdown.Item>
                    </LinkContainer>
                  </Can>
                  <Can I="read" a="DailyTruckReport">
                    <LinkContainer to="/reporting/daily-truck-report">
                      <NavDropdown.Item href="/reporting/daily-truck-report">
                        Daily Truck Report
                      </NavDropdown.Item>
                    </LinkContainer>
                  </Can>
                  <Can I="debug" a="ErrorReporting">
                    <NavDropdown.Item onClick={testErrorHandling}>
                      Test Error Handling
                    </NavDropdown.Item>
                  </Can>
                </NavDropdown>
              )}
            </Nav>
            {currentUser && (
              <div className="ml-auto">
                {showGoToJobModal && (
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id="logout-tooltip">Go To Job</Tooltip>}
                  >
                    <Button
                      variant="link"
                      className="text-dark"
                      onClick={() => {
                        window.dispatchEvent(new CustomEvent('go-to-job'));
                      }}
                    >
                      <Icon name="forward" />
                    </Button>
                  </OverlayTrigger>
                )}
                <Navbar.Text className="ml-3">
                  Hi, {currentUser.name || currentUser.username || 'User'}!
                </Navbar.Text>
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip id="logout-tooltip">Logout</Tooltip>}
                >
                  <Button variant="link" className="text-dark" onClick={handleLogout}>
                    <Icon name="user" />
                  </Button>
                </OverlayTrigger>
              </div>
            )}
          </Container>
        </Navbar>
      )}
      <GlobalAlerts className="sticky-top m-3" dismissInSeconds={5} />
      {showGoToJobModal && <GoToJobModal />}
      <Suspense
        fallback={
          <div className="w-100" style={{ height: '75vh' }}>
            <Loading />
          </div>
        }
      >
        <Route exact path={routes.SIGN_IN} component={SignIn} />
        <Container fluid="xl" className="app">
          <Row>
            <Col>
              {!currentUser && isLoggedIn && (
                <div className="d-flex w-100 mt-5">
                  <Spinner animation="border" className="m-auto" />
                </div>
              )}
              {/*
              We have to switch on AuthorizedRoutes after currentUser is loaded,
              otherwise we will always be redirected to "/" on page reloads because
              the isAuthorized property passed to AuthorizedRoute will be false
              */}
              {currentUser && (
                <Switch>
                  <ScheduleRoute
                    isAuthorized={ability.can('read', 'PavingSchedule')}
                    exact
                    setEditMode={setEditMode}
                    path="/schedule-v2/paving"
                    component={ScheduleV2}
                  />
                  <ScheduleRoute
                    isAuthorized={ability.can('read', 'ConcreteSchedule')}
                    exact
                    setEditMode={setEditMode}
                    path="/schedule-v2/concrete"
                    component={ScheduleV2}
                  />
                  <ScheduleRoute
                    isAuthorized={ability.can('read', 'StripingSchedule')}
                    exact
                    setEditMode={setEditMode}
                    path="/schedule-v2/striping"
                    component={ScheduleV2}
                  />
                  <ScheduleRoute
                    setEditMode={setEditMode}
                    isAuthorized={ability.can('read', 'SealingSchedule')}
                    exact
                    path="/schedule-v2/sealing"
                    component={ScheduleV2}
                  />
                  <ScheduleRoute
                    isAuthorized={ability.can('read', 'AllSchedule')}
                    exact
                    setEditMode={setEditMode}
                    path="/schedule-v2/all"
                    component={ScheduleV2}
                  />
                  <AuthorizedRoute
                    isAuthorized={ability.can('read', 'Schedule')}
                    exact
                    path="/schedule-v2/edit"
                    component={ScheduleV2}
                  />
                  <ScheduleRoute
                    isAuthorized={ability.can('read', 'AllSchedule')}
                    exact
                    setEditMode={setEditMode}
                    path="/schedule/all"
                    component={Schedule}
                  />
                  <ScheduleRoute
                    isAuthorized={ability.can('read', 'PavingSchedule')}
                    exact
                    setEditMode={setEditMode}
                    path="/schedule/paving"
                    component={Schedule}
                  />
                  <ScheduleRoute
                    isAuthorized={ability.can('read', 'ConcreteSchedule')}
                    exact
                    setEditMode={setEditMode}
                    path="/schedule/concrete"
                    component={Schedule}
                  />
                  <ScheduleRoute
                    isAuthorized={ability.can('read', 'StripingSchedule')}
                    exact
                    setEditMode={setEditMode}
                    path="/schedule/striping"
                    component={Schedule}
                  />
                  <AuthorizedRoute
                    isAuthorized={ability.can('read', 'Schedule')}
                    exact
                    path="/schedule/edit"
                    component={Schedule}
                  />
                  <AuthorizedRoute
                    isAuthorized={ability.can('read', 'Schedule')}
                    exact
                    path="/schedule-v2"
                    component={ScheduleV2}
                  />
                  <AuthorizedRoute
                    isAuthorized={ability.can('read', 'Schedule')}
                    exact
                    path="/schedule-v2/edit"
                    component={ScheduleV2}
                  />
                  <AuthorizedRoute
                    isAuthorized={ability.can('read', 'Job')}
                    exact
                    path="/jobs"
                    component={Jobs}
                  />
                  <AuthorizedRoute
                    isAuthorized={ability.can('read', 'Job')}
                    path="/jobs/:jobId"
                    component={Job}
                  />
                  <AdminRoute
                    isAuthorized={ability.can('read', 'User')}
                    path="/admin/users"
                    component={Users}
                  />
                  <AdminRoute
                    isAuthorized={ability.can('read', 'Resource')}
                    path="/admin/resource-management"
                    component={ResourceManagement}
                  />
                  <AuthorizedRoute
                    isAuthorized={ability.can('read', 'Job')}
                    exact
                    path="/reporting/job-review"
                    component={JobReview}
                  />
                  <AuthorizedRoute
                    isAuthorized={ability.can('read', 'ReadyToBillReport')}
                    exact
                    path="/reporting/ready-to-bill"
                    component={ReadyToBill}
                  />
                  <AuthorizedRoute
                    isAuthorized={ability.can('read', 'ProfitMarginReport')}
                    exact
                    path="/reporting/profit-margin-report"
                    component={ProfitMarginReport}
                  />
                  <AuthorizedRoute
                    isAuthorized={ability.can('read', 'SchedulePublishedReport')}
                    exact
                    path="/reporting/schedule-published-report"
                    component={SchedulePublishedReport}
                  />
                  <AuthorizedRoute
                    isAuthorized={ability.can('read', 'ScheduledJobsReport')}
                    exact
                    path="/reporting/scheduled-jobs-report"
                    component={ScheduledJobsReport}
                  />
                  <AuthorizedRoute
                    isAuthorized={ability.can('read', 'HoursByStateReport')}
                    exact
                    path="/reporting/hours-by-state"
                    component={HoursByState}
                  />
                  <AuthorizedRoute
                    isAuthorized={ability.can('read', 'ConesLeftBehindReport')}
                    exact
                    path="/reporting/cones-left-behind"
                    component={ConesLeftBehind}
                  />
                  <AuthorizedRoute
                    isAuthorized={ability.can('read', 'DailyTruckReport')}
                    exact
                    path="/reporting/daily-truck-report"
                    component={DailyTruckReport}
                  />
                  <Redirect to={currentUserHome ?? '/'} />
                </Switch>
              )}
            </Col>
          </Row>
        </Container>
      </Suspense>
    </ErrorBoundary>
  );
};

export default App;
