import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { request } from '@shared/api';
import { SessionService } from '@shared/modules';
import { AppThunk, RootState } from '@shared/store';
import { User as UserInterface } from '@shared/types';
import { CurrentUserState, LoginCredentials } from './types';

const initialState: CurrentUserState = {
  isLoggedIn: false,
  isLoading: false
};

export const login = createAsyncThunk('currentUser/login', async (user: LoginCredentials, {}) => {
  const response = await request<UserInterface, Record<'user', LoginCredentials>>(
    'post',
    'users/login',
    {
      user
    }
  );

  return response;
});

export const getCurrentUser = createAsyncThunk('currentUser/getCurrentUser', async () => {
  const response = await request<UserInterface>('get', 'users/me');

  return response;
});

const currentUserSlice = createSlice({
  name: 'currentUser',
  initialState,
  reducers: {
    clearCurrentUser(state) {
      state.currentUser = undefined;
      state.isLoggedIn = false;
    },
    setCurrentUserError(state, action: PayloadAction<string | string[] | undefined>) {
      if (!action.payload) {
        state.errorMessages = action.payload as undefined;
      } else {
        state.errorMessages = Array.isArray(action.payload) ? action.payload : [action.payload];
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(login.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.currentUser = action.payload;
      state.isLoggedIn = true;
      state.isLoading = false;
    });
    builder.addCase(login.rejected, (state, action) => {
      if (action.error.message) {
        state.errorMessages = [action.error.message];
      }
      state.isLoggedIn = false;
      state.isLoading = false;
    });
    builder.addCase(getCurrentUser.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getCurrentUser.fulfilled, (state, action) => {
      state.currentUser = action.payload;
      state.isLoggedIn = true;
      state.isLoading = false;
    });
    builder.addCase(getCurrentUser.rejected, (state, action) => {
      if (action.error.message) {
        state.errorMessages = [action.error.message];
      }
      state.isLoggedIn = false;
      state.isLoading = false;
    });
  }
});

export const { clearCurrentUser, setCurrentUserError } = currentUserSlice.actions;
export default currentUserSlice.reducer;

export const logout = (): AppThunk => async dispatch => {
  SessionService.clearSession();
  dispatch(clearCurrentUser());
};

export const useCurrentUserSelector = (): CurrentUserState =>
  useSelector<RootState, CurrentUserState>(state => state.currentUser);
