import React, { useCallback, useState } from 'react';

export const useLightbox = () => {
  const [showModal, setShowModal] = useState(false);
  const [focusedImage, setFocusedImage] = useState<string | null>(null);

  const handleShowModal: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    event => {
      const { src } = event.currentTarget.querySelector('img') as HTMLImageElement;
      setFocusedImage(src ?? null);
      setShowModal(true);
    },
    [setFocusedImage, setShowModal]
  );

  const handleHideModal = useCallback(() => {
    setShowModal(false);
    setTimeout(() => setFocusedImage(null), 300);
  }, [setShowModal, setFocusedImage]);

  return {
    showModal,
    focusedImage,
    handleShowModal,
    handleHideModal
  };
};
