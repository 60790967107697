import React from 'react';
import { Button, ButtonProps, Modal } from 'react-bootstrap';

import { IconWithText } from '../icon-with-text';
import './styles/confirmation-modal.scss';
import { ButtonVariant } from 'react-bootstrap/esm/types';

export interface ConfirmationModalProps {
  /** Content to render inside the modal. */
  children: JSX.Element | null;

  /** Sets the visibility of the modal. */
  isVisible: boolean;

  /**
   * Displays a loading state inside the modal for situations where content
   * is loaded from a remote source.
   */
  isLoadingContent?: boolean;

  /** Sets the message below the loading animation icon. */
  loadingContentMessage?: string;

  /** Callback fired when the **Cancel** button is clicked. */
  onCancel(): void;

  /** Callback fired when the **Confirm** button is clicked. */
  onConfirm(): void;

  /** Callback fired when the modal is hidden. */
  onHide(): void;

  /** Sets a custom label for the **Confirm** button.  */
  positiveLabel?: string;

  /** Sets a variant for the **Confirm** button. */
  positiveVariant?: ButtonVariant;

  /** Locks the **Confirm** button and displays a processing state.  */
  isProcessing?: boolean;

  /** Sets the size of the modal. */
  size: 'sm' | 'lg' | 'xl';

  /** Sets a custom title for the modal. */
  title?: string;
}

export const ConfirmationModal = ({
  children,
  isVisible,
  onCancel,
  onConfirm,
  onHide,
  positiveLabel,
  positiveVariant = 'primary',
  isProcessing,
  size,
  title
}: ConfirmationModalProps) => (
  <Modal size={size} onHide={onHide} show={isVisible} dialogClassName="confirmation-modal">
    <Modal.Header>
      <Modal.Title as="h2">{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>{children}</Modal.Body>
    <Modal.Footer>
      <Button disabled={isProcessing} onClick={onCancel} variant="outline-primary">
        Cancel
      </Button>
      <Button variant={positiveVariant} disabled={isProcessing} onClick={onConfirm}>
        {isProcessing ? <IconWithText iconName="save">Working…</IconWithText> : positiveLabel}
      </Button>
    </Modal.Footer>
  </Modal>
);

ConfirmationModal.defaultProps = {
  children: null,
  isLoadingContent: false,
  loadingContentMessage: 'Loading…',
  positiveLabel: 'Confirm',
  isProcessing: false,
  title: 'Confirmation'
};
