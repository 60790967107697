import { Ability, defineAbility, SubjectType } from '@casl/ability';
import { userRolesEnum } from '@shared/constants';
import { DetailedJob, RailsObject, User } from '@shared/types';
import { has } from 'lodash-es';

const {
  FOREMAN,
  SALES_REP,
  ADMIN,
  PAVING_SCHEDULER,
  CONCRETE_SCHEDULER,
  STRIPING_SCHEDULER,
  SEALING_SCHEDULER,
  SCHEDULE_VIEWER,
  SUPERINTENDENT
} = userRolesEnum;

export const abilityFor = (user: User | undefined): Ability => {
  return defineAbility<Ability>(
    (can, cannot) => {
      if (!user) return;

      if (user.role === ADMIN) {
        can('manage', 'all');
        can('debug', 'all');
      }

      if (user.role === SUPERINTENDENT) {
        can('read', 'all');
        cannot('create', 'Resource');
        cannot('edit', 'Resource');
      }

      if (user.role === SCHEDULE_VIEWER) {
        can('read', 'Schedule');
        can('read', 'AllSchedule');
      }

      if (user.role === FOREMAN) {
        // TODO can foreman do anything through the web?
        // can('read', 'Job');
        // can('read', 'DailyJobReview');
        // can('read', 'Schedule');
        // can('read', 'ReadyToBillReport');
        // can('read', 'HoursByStateReport');
        // can('read', 'User');
        // can('read', 'Resource');
      }

      if (user.role === SALES_REP) {
        can('read', 'Job', { salesRepId: { $eq: user.employeeId } });
        can('manage', 'Job', { salesRepId: { $eq: user.employeeId } });
        can('read', 'Schedule');
        can('read', 'AllSchedule');
        cannot('manage', 'JobReport');
        cannot('manage', 'TruckReport');
        can('read', 'PavingSchedule');
        can('read', 'ConcreteSchedule');
        can('read', 'StripingSchedule');
        can('read', 'SealingSchedule');
      }

      if (user.role === PAVING_SCHEDULER) {
        can('manage', 'Schedule');
        can('read', 'PavingSchedule');
        can('read', 'AllSchedule');
        can('read', 'Job');
        can('manage', 'Job', { salesRepId: { $eq: user.employeeId } });
        cannot('manage', 'JobReport');
        cannot('manage', 'TruckReport');
      }

      if (user.role === CONCRETE_SCHEDULER) {
        can('manage', 'Schedule');
        can('read', 'ConcreteSchedule');
        can('read', 'AllSchedule');
        can('read', 'Job');
        can('manage', 'Job', { salesRepId: { $eq: user.employeeId } });
        cannot('manage', 'JobReport');
        cannot('manage', 'TruckReport');
      }

      if (user.role === STRIPING_SCHEDULER) {
        can('manage', 'Schedule');
        can('read', 'StripingSchedule');
        can('read', 'AllSchedule');
        can('read', 'Job');
        can('manage', 'Job', { salesRepId: { $eq: user.employeeId } });
        cannot('manage', 'JobReport');
        cannot('manage', 'TruckReport');
      }

      if (user.role === SEALING_SCHEDULER) {
        can('manage', 'Schedule');
        can('read', 'SealingSchedule');
        can('read', 'AllSchedule');
        can('read', 'Job');
        can('manage', 'Job', { salesRepId: { $eq: user.employeeId } });
        cannot('manage', 'JobReport');
        cannot('manage', 'TruckReport');
      }

      if ((user.permissions??[]).includes('schedule_published_report'))
      {
        can('read', 'SchedulePublishedReport');
      }
      else
      {
        cannot('read', 'SchedulePublishedReport');
      }

    },
    {
      detectSubjectType: object => {
        // Here we can define named objects by checking for the presence of one or more keys.
        // The keys aren't required to have values, they merely have to be present.
        const FIELDS = {
          Job: ['id', 'num', 'salesRepId'],
          Schedule: ['id', 'date', 'crewListTemplateIds', 'crewListTemplates'],
          JobReport: ['id', 'startAt', 'endAt', 'devicesLeftBehind'],
          TruckReport: ['id', 'driverName', 'truckNumber', 'roundCount', 'tollCount']
        };

        for (const field in FIELDS) {
          if (FIELDS[field].every(key => has(object, key))) return field;
        }

        return 'Object';
      }
    }
  );


};
