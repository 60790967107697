import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
  ReactNode,
  Fragment
} from 'react';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  Column as ReactTableColumn,
  ColumnInstance,
  UseSortByColumnProps,
  useTable,
  usePagination,
  useFlexLayout,
  useSortBy,
  Row,
  TableInstance,
  UseTableHeaderGroupProps,
  SortingRule, UseSortByOptions, useBlockLayout
} from 'react-table';
import { Table, TableProps as BSTableProps } from 'react-bootstrap';
import classnames from 'classnames';

import './styles/resource-grid.scss';
import { PagePrams, PaginatedData } from '@shared/types';
import { FilterControlInterface, FilterValueInterface, Icon } from '@components/common';
import { searchItems, usePrevious } from '@shared/utils';
import { isEmpty, isEqual, isFunction } from 'lodash';
import { ResourceGridFilters, ResourceGridPagination } from './partials';

declare module 'react-table' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnInstance<D> {
    className?: string;
    headerClassName?: string;
    cellClassName?: string;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface TableState<D> extends UsePaginationState<D>, UseSortByState<D> {}

  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface TableOptions<D> extends UsePaginationOptions<D>, UseSortByOptions<D> {}

  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface TableInstance<D> extends UsePaginationInstanceProps<D>, UseSortByInstanceProps<D> {}
}

type RowData = object & {
  startsRowGroup?: boolean;
  className?: string;
};

export interface ResourceGridProps<D extends RowData> extends BSTableProps {
  /** Optional filtering controls, using the `FilterControl` interface. */
  filterControls?: FilterControlInterface[];
  /** If `true`, reveals an **Update** button and requires a manual click to apply filtering. */
  requireManualUpdate?: boolean;
  /** Column definitions for the `react-table` instance. */
  columns: ReactTableColumn<D>[];
  /** A collection of objects to render within the table. */
  data: D[];
  /** Triggers **server-side pagination** of filtering and pagination. **/
  fetchData?: (
    page: PagePrams,
    filter?: Record<string, unknown>
  ) => void | Promise<PayloadAction<PaginatedData<D>>>;
  /**
   * If you are passing a `fetchData` function to do server-side pagination and filtering
   * you cannot toggle this component based on `isFetchingData` flag from a parent component. That's
   * because `fetchData` is called as soon as this component is mounted which then toggles the `isFetchingData` flag
   * which in turn un-mounts and re-mounts this component and that cycle repeats indefinitely.
   * Instead you can pass `isFetchingData` to this component and it will toggle the content accordingly
   * */
  isFetchingData?: boolean;
  /** Override the internally-computed page number. */
  pageNumber?: number;
  /** Override the internally-computed page count. */
  pageCount?: number;
  /** In normal operation, sets a default page size. When using **server-side pagination**,
   *  enforces external control over the page size. Defaults to 10.
   **/
  pageSize?: number;
  /** Required when using **server-side pagination**. */
  totalRowCount?: number;
  /** If `false`, pagination controls are hidden. */
  showPagination?: boolean;
  /** If `false`, the table header is hidden. */
  showHeaderRow?: boolean;
  /** Only useful in client side filtering mode */
  onDataFiltered?: (data: D[]) => void;
  /** Sets an initial set of filter values. */
  initialFilterValues?: FilterValueInterface;
  /**
   * If `true`, will enable the `useBlockLayout` plugin for react-table.
   * Note that all columns will **require** `width`, `minWidth` and `maxWidth` values.
   * @see: https://react-table.tanstack.com/docs/api/useBlockLayout
   **/
  blockLayout?: boolean;
  /** An optional value to establish an internal "context". Can be used to trigger effects. */
  viewContext?: string;
  /** Message to display when no data is available. */
  noRowsMessage?: string;
  /** Render a custom header row. */
  renderRowGroupHeader?: (starterRow: D) => ReactNode;
  /** Callback fired when a row is clicked. */
  onRowClick?: (row: Row<D>, event: React.MouseEvent<HTMLTableRowElement>) => void;
  /** A callback to pass the internally-computed page number and size back to a parent. `number` is **one-indexed**. */
  updatePagePrams?: (number: number, size: number) => void;
  /** If present, triggers the `useSortBy` plugin for react-table and enables sorting for only the named columns. */
  sortColumns?: string[];
  /** If using `sortColumns`, defines an initial sort order to pass to react-table. */
  initialSort?: SortingRule<D>[];
  /**
   * Optional render function to insert children into the ResourceGrid. The function will receive
   * the full table instance. This can be useful for rendering custom controls, or
   * delegating callbacks to a parent component.
   **/
  children?: (props: TableInstance<D>) => JSX.Element;
}

export const ResourceGrid = <D extends RowData>({
                                                  filterControls = [],
                                                  requireManualUpdate = true,
                                                  columns,
                                                  data,
                                                  pageNumber: controlledPageNumber = 1,
                                                  pageSize: controlledPageSize,
                                                  pageCount: controlledPageCount,
                                                  fetchData,
                                                  isFetchingData,
                                                  totalRowCount,
                                                  showPagination = true,
                                                  showHeaderRow = true,
                                                  blockLayout = false,
                                                  noRowsMessage = 'No rows available.',
                                                  renderRowGroupHeader,
                                                  onRowClick,
                                                  onDataFiltered,
                                                  updatePagePrams,
                                                  viewContext,
                                                  initialFilterValues = {},
                                                  sortColumns,
                                                  initialSort,
                                                  children,
                                                  ...tableProps
                                                }: ResourceGridProps<D>): JSX.Element => {
  const [activeFilterValues, setActiveFilterValues] = useState<FilterValueInterface>(
    initialFilterValues
  );
  const [selectedPageSize, setSelectedPageSize] = useState(controlledPageSize ?? 10);
  const isSearchingRef = useRef(false);

  const filteredData = useMemo(() => {
    if (fetchData || isEmpty(activeFilterValues)) return data;
    return searchItems(data, {
      filterValues: activeFilterValues,
      filterKeys: filterControls.reduce(
        (final, control) => ({
          ...final,
          [control.slug]: control.searchKeys || control.slug
        }),
        {}
      )
    });
  }, [data, activeFilterValues, filterControls, fetchData]);

  const preparedColumns = useMemo(() => {
    return columns.map(column => {
      return {
        ...column,
        disableSortBy: !(sortColumns ?? []).includes(
          (column.id || column.accessor)?.toString() ?? ''
        )
      };
    });
  }, [columns, sortColumns]);

  const preparedSortBy = useMemo(() => {
    return initialSort;
  }, [initialSort]);

  useEffect(() => {
    onDataFiltered?.(filteredData);
  }, [filteredData, onDataFiltered]);

  const totalRows = totalRowCount ?? data.length;
  const tableInstance = useTable<D>(
    {
      columns: preparedColumns,
      data: filteredData,
      initialState: {
        pageIndex: controlledPageNumber - 1,
        pageSize: selectedPageSize ?? 1,
        sortBy: preparedSortBy || []
      },
      disableMultiSort: true,
      autoResetPage: false,
      manualPagination: fetchData != null,
      pageCount: controlledPageCount ?? Math.ceil(totalRows / selectedPageSize)
    },
    useSortBy,
    usePagination,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    blockLayout ? useFlexLayout : () => {}
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state,
    pageCount,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize }
  } = tableInstance;

  const previousViewContext = usePrevious(viewContext);
  const previousPageNumber = usePrevious(controlledPageNumber);
  const previousControlledPageSize = usePrevious(controlledPageSize);
  const previousActiveFilterValues = usePrevious(activeFilterValues);
  const previousFilterControlsLength = usePrevious(filterControls.length);

  /**
   * Handles database calls. Returns a resolved Promise if no `fetchData` callback is present
   *
   * @param number The page number. **Must be one-indexed**.
   * @param size The page size.
   */
  const handleFetchData = useCallback(
    async (number = pageIndex, size = pageSize, filters = activeFilterValues): Promise<void> => {
      if (!isFunction(fetchData)) return Promise.resolve();

      return new Promise(resolve => {
        isSearchingRef.current = true;

        const request = fetchData({ number, size }, filters);

        if (request) {
          request
            .then(() => {
              if (isFunction(updatePagePrams)) {
                updatePagePrams(number, size);
              }
            })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              setTimeout(() => {
                isSearchingRef.current = false;
                resolve();
              }, 1000);
            });
        } else {
          isSearchingRef.current = false;
        }
      });
    },
    [fetchData, activeFilterValues, pageIndex, pageSize, updatePagePrams]
  );

  /**
   * Handler for changing the page. Note that this function expects a **one-indexed**
   * page number, and will translate it as necessary. By default, will attempt to fetch
   * remote data.
   *
   * @param pageNumber The page to go to. **Must be one-indexed**.
   * @param fetch Whether or not to fetch remote data. Defaults to `true`.
   */
  const handleGoToPage = useCallback(
    async (pageNumber: number, fetch = true) => {
      // react-table uses zero-indexing for pages
      gotoPage(pageNumber - 1);

      if (fetch && !isSearchingRef.current) await handleFetchData(pageNumber);
    },
    [gotoPage, handleFetchData]
  );

  /**
   * Handler for changing the page size. By default, will attempt to fetch remote data.
   *
   * @param size The new page size.
   * @param fetch Whether or not to fetch remote data. Defaults to `true`.
   */
  const handleSetSelectedPageSize = useCallback(
    async (size: number, fetch = true) => {
      setSelectedPageSize(size);
      setPageSize(size);
      handleGoToPage(1, false);

      if (fetch && !isSearchingRef.current) await handleFetchData(1, size);
    },
    [setSelectedPageSize, setPageSize, handleFetchData, handleGoToPage]
  );

  /**
   * Handler for changing the current filter value set. By default, will attempt to
   * fetch remote data.
   *
   * @param size The new page size.
   * @param fetch Whether or not to fetch remote data. Defaults to `true`.
   */
  const handleActiveFiltersChange = useCallback(
    async (filterValues: Record<string, string>, fetch = true) => {
      setActiveFilterValues(filterValues);
      handleGoToPage(1, false);

      if (fetch && !isSearchingRef.current) await handleFetchData(1, pageSize, filterValues);
    },
    [setActiveFilterValues, handleGoToPage, handleFetchData, pageSize]
  );

  /**
   * Handler to accept incoming page number and size props and translate them
   * to the internal data set.
   *
   * @param number A page to go to. **Must be one-indexed**.
   * @param size A page size to apply.
   */
  const handleApplyControlledProps = useCallback(
    (number: number, size: number, forceLoad = false) => {
      let shouldLoad = forceLoad;

      if (size !== previousControlledPageSize) {
        handleSetSelectedPageSize(size, false);
        shouldLoad = true;
      } else if (number !== previousPageNumber) {
        handleGoToPage(number, false);
        shouldLoad = true;
      }

      if (shouldLoad) handleFetchData(number, size);
    },
    [
      previousControlledPageSize,
      previousPageNumber,
      handleSetSelectedPageSize,
      handleGoToPage,
      handleFetchData
    ]
  );

  const handleApplyFilterValues = useCallback(
    async (
      filterControls: FilterControlInterface[],
      filterValues: Record<string, string> = {},
      fetch = true
    ) => {
      // wait for filters to load before attempting to apply values. this ensures existing values don't get wiped out
      if (filterControls.length === 0) return;


      const updatedFilterValues = filterControls.reduce((final, control) => {
        if (filterValues[control.slug]) {
          final[control.slug] = filterValues[control.slug];
        }

        return final;
      }, {});

      setActiveFilterValues(updatedFilterValues);

      if (fetch && !isSearchingRef.current) await handleFetchData(1, pageSize, updatedFilterValues);
    },
    [setActiveFilterValues, handleFetchData, pageSize]
  );

  useEffect(() => {
    if (controlledPageSize !== undefined && controlledPageNumber !== undefined) {
      handleApplyControlledProps(controlledPageNumber, controlledPageSize);
    }
  }, [handleApplyControlledProps, controlledPageSize, controlledPageNumber]);

  useEffect(() => {
    if (viewContext !== previousViewContext) {
      handleApplyControlledProps(1, 10, true);
    }
  }, [viewContext, previousViewContext, handleApplyControlledProps]);

  useEffect(() => {
    if (filterControls.length !== previousFilterControlsLength) {
      handleApplyFilterValues(filterControls, activeFilterValues);
    }
  }, [
    filterControls,
    previousFilterControlsLength,
    activeFilterValues,
    previousActiveFilterValues,
    handleApplyFilterValues
  ]);

  return (
    <div className={classnames('resource-grid', { 'use-flex-layout': blockLayout === true })}>
      {filterControls.length > 0 && (
        <div className="resource-grid__filters">
          <ResourceGridFilters
            {...{
              filterControls,
              activeFilterValues,
              setActiveFilterValues: handleActiveFiltersChange,
              requireManualUpdate
            }}
          />
        </div>
      )}
      <div className="resource-grid-table position-relative">
        <div
          className={classnames('resource-grid-loader', {
            'resource-grid-loader--visible': isFetchingData
          })}
        >
          <div className="d-flex m-auto align-items-center justify-content-center">
            <Icon
              name="truck"
              animation="pulse"
              animationSpeed="slow"
              size="huge"
              className="mr-3"
            />
            Loading…
          </div>
        </div>
        <Table {...getTableProps()} {...tableProps}>
          {showHeaderRow && (
            <thead>
              {headerGroups.map((headerGroup: ColumnInstance<D> & UseTableHeaderGroupProps<D>) => (
                <tr
                  {...headerGroup.getHeaderGroupProps({
                    style: {
                      height: blockLayout ? '100%' : 'auto'
                    }
                  })}
                >
                  {headerGroup.headers.map(
                    (
                      column: ColumnInstance<D> &
                        UseTableHeaderGroupProps<D> &
                        UseSortByColumnProps<D>
                    ) => (
                      <th
                        {...column.getHeaderProps({
                          ...{ className: column.headerClassName || column.className },
                          ...(column &&
                            typeof column.getSortByToggleProps === 'function' &&
                            column.getSortByToggleProps()),
                          style: {
                            ...column.getHeaderProps().style,
                            maxWidth: column.maxWidth
                          }
                        })}
                      >
                        <div
                          className={classnames('d-flex align-items-center', {
                            'pr-4': column.canSort,
                            'position-relative': column.canSort
                          })}
                        >
                          {column.render('Header')}
                          {column.canSort && (
                            <span className="position-absolute right-0 bottom-0">
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <Icon name="chevron-down" />
                                ) : (
                                  <Icon name="chevron-up" />
                                )
                              ) : (
                                <Icon name="sortable" />
                              )}
                            </span>
                          )}
                        </div>
                      </th>
                    )
                  )}
                </tr>
              ))}
            </thead>
          )}
          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row);
              const originalRow = row.original;
              return (
                <Fragment key={row.id}>
                  {originalRow.startsRowGroup && renderRowGroupHeader && (
                    <>
                      <tr>
                        <td colSpan={columns.length} className="p-0">
                          {renderRowGroupHeader(originalRow)}
                        </td>
                      </tr>
                      {/* conditionally add a hidden row to start over the striping pattern for normal rows */}
                      {row.index % 2 === 0 && <tr className="d-none" />}
                    </>
                  )}
                  <tr
                    {...row.getRowProps({ className: row.original.className })}
                    onClick={event => {
                      onRowClick?.(row, event);
                    }}
                    style={{ cursor: onRowClick ? 'pointer' : undefined }}
                  >
                    {row.cells.map(cell => {
                      return (
                        <td
                          {...cell.getCellProps({
                            className: cell.column.cellClassName || cell.column.className,
                            style: {
                              ...cell.getCellProps().style,
                              maxWidth: cell.column.maxWidth
                            }
                          })}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </Table>
        {showPagination && (
          <ResourceGridPagination
            {...{
              canNextPage,
              canPreviousPage,
              page,
              pageOptions,
              pageCount,
              setPageSize: handleSetSelectedPageSize,
              isFetchingData,
              state
            }}
            gotoPage={(value: number) => {
              handleGoToPage(value + 1);
            }}
            totalRows={totalRows}
            nextPage={() => {
              handleGoToPage(pageIndex + 2);
            }}
            previousPage={() => {
              handleGoToPage(pageIndex);
            }}
          />
        )}
        {totalRows === 0 && (
          <div className="d-flex flex-column w-100 p-3 align-items-center justify-content-center">
            <p className="h3 mt-5">{noRowsMessage}</p>
          </div>
        )}
      </div>
      {children && isFunction(children) && children(tableInstance)}
    </div>
  );
};
