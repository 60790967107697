import React, { isValidElement, cloneElement } from 'react';
import classnames from 'classnames';

import './styles/data-point.scss';

export type DataPointSize = 'small' | 'medium' | 'large' | 'huge' | 'giant';
export type DataPointModifier = 'staggered' | 'emphasis' | 'display' | 'super-detail';

export interface DataPointProps extends React.DetailedHTMLProps<React.HTMLAttributes<any>, any> {
  /** One or more elements or strings to render. */
  children: React.ReactNode;
  /** The overall size of the data point. */
  size?: DataPointSize;
  /** Make the callout larger to give it more emphasis. */
  isCallout?: boolean;
  /** Align all text inline. */
  isInline?: boolean;
  /** Center all text within the data point. */
  isCentered?: boolean;
  /** Visual modifiers. */
  modifiers?: DataPointModifier[];
}

export const DataPoint: React.FC<DataPointProps> = ({
  size = 'medium',
  isCallout = false,
  isCentered = false,
  isInline = false,
  modifiers = [],
  children,
  className,
  ...props
}: DataPointProps) => {
  return (
    <div
      className={classnames(
        'data-point',
        {
          [`data-point--${size}`]: size !== undefined,
          'data-point--callout': isCallout,
          'data-point--centered': isCentered,
          'data-point--inline': isInline
        },
        ...modifiers.map(modifier => `data-point--${modifier}`),
        className
      )}
      {...props}
    >
      {React.Children.map(children, child => {
        if (isValidElement(child)) {
          return cloneElement(child, {
            className: classnames('data-point__text', child.props.className)
          });
        }

        return <span className="data-point__text">{child}</span>;
      })}
    </div>
  );
};
